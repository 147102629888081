import React, { useRef } from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
// Components
import Layout from "components/Layout";
import {
  Page,
  PageSidebar,
  PageCategories,
  PageTags,
  PageHeader,
  PageTitle,
} from "components/Page";
import HTMLContent from "components/HTMLContent";
import HeadTags from "components/Head";
// Types
import { Category } from "types/Categories";
import { MarkdownContent } from "types/MarkdownRemark";
import { ArticleContext } from "types/PageContext";

type DataProps = {
  markdownRemark: MarkdownContent;
};

export const Template: React.FC<PageProps<DataProps, ArticleContext>> = ({
  data,
  pageContext,
}) => {
  const { categories, tags } = pageContext;
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { id, slug, title } = frontmatter;
  const htmlContentRef = useRef<HTMLDivElement>(null);
  return (
    <Layout title={title} slug={slug}>
      <Page id={id}>
        <PageHeader>
          <PageTitle>{title}</PageTitle>
          <PageCategories categories={categories || []} />
          <PageTags tags={tags || []} />
        </PageHeader>
        <HTMLContent ref={htmlContentRef}>{html}</HTMLContent>
      </Page>
      <PageSidebar contentRef={htmlContentRef} />
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps, ArticleContext>) => (
  <HeadTags {...props} />
);

export default Template;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        authors
        categories
        tags
      }
    }
  }
`;
